import React from 'react';

import '../styles/Home.css';

import BackedBy from '../components/BackedBy.js';
import CallToAction from '../components/CallToAction.js';

function Home() {
  return (
    <div className='home-container'>
        <CallToAction />
        <BackedBy />
    </div>
  );
}

export default Home;