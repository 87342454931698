import React from 'react';
import { Outlet } from "react-router-dom";

import FooterPage from '../components/Footer.js';
import NavBar from '../components/NavBar.js';
import '../styles/Layout.css';

import { animated, useTransition } from 'react-spring';

function Layout() {
  const transition = useTransition(true, {
    from: {opacity: 0},
    enter: {opacity: 1},
    config: {duration: 600}
  })
  return (
    <div className="layout-background">
      {transition((style, item) =>
        item ? <animated.div style={style} className="item">
          <div className="layout">
            <NavBar />
            <Outlet />
            <FooterPage />
          </div>  
        </animated.div> : ''
      )}
    </div>
  );
}
  
  export default Layout;