import React, { Component } from 'react';
import '../styles/CallToAction.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class CallToAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  render() {
    return (
      <Container className="cta">
        <Col>
          <Row className="scalability justify-content-center">
            Castings in Days, not Months
          </Row>
          <Row className="launch-beta justify-content-center">
            <a href="/schedule" className="launch-beta-link">
              Schedule a Meeting
            </a>
          </Row>
          <Row className="cta-p justify-content-center">
            Fabri leverages additive manufacturing to produce OE quality castings, without the OE lead times and MOQs.
          </Row>
        </Col>
      </Container>
    );
  }
}

export default CallToAction;