import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import image1 from '../assets/steve.png';
import image3 from '../assets/hart.jpg';

import "../styles/TransparentCard.css"

class TeamMembers extends Component {
  render() {
    return (
      <Container className="team">
        <Row className="justify-content-around">
          <Col md={3}>
            <Card className="transparent-card">
              <Card.Img variant="top" src={image1} alt="Team Member 1" />
              <Card.Body>
                <Card.Title>Steven Davis, CEO</Card.Title>
                <p>MIT Mechanical Engineering</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="transparent-card">
              <Card.Img variant="top" src={image3} alt="Team Member 3" />
              <Card.Body>
                <Card.Title>A. John Hart, Lead Advisor</Card.Title>
                <p>MIT Mechanical Engineering</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default TeamMembers;

