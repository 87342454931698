import React from 'react';

import '../styles/Home.css';
import TeamMembers from '../components/TeamMembers';

function MeetUs() {
  return (
    <div className='home-container'>
        <TeamMembers />
    </div>
  );
}

export default MeetUs;
