import React from 'react';
import fabriwhite from '../assets/fabriwhite.png'

import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter className='text-center text-lg-start text-muted'>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
              <img
                src={fabriwhite}
                width="115"
                height="30"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
            />
              </h6>
              <p className="text-white">
                Modern manufacturing
              </p>
            </MDBCol>
            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase text-white fw-bold mb-4'>Contact</h6>
              <p className="text-white">
                <MDBIcon icon="home" className="text-white me-2" />
                526 Beacon St, Boston, MA
              </p>
              <p className="text-white">
                <MDBIcon icon="envelope" className="text-white me-3" />
                smdavis@fabriusa.com
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center text-white p-4'>
        © 2023 Copyright:{' '}
        <a className='text-reset text-white fw-bold' href='https://fabriusa.com'>
          Fabri Inc. 
        </a>
      </div>
    </MDBFooter>
  );
}