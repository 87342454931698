import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import fabriwhite from '../assets/fabriwhite.png';
import fabriwhiteboat from '../assets/fabriwhiteboat.png';

import '../styles/Navbar.css';

function FabriNavbar() {
  return (
    
    <Navbar className="shadow-none"  expand="lg" variant="dark">
        <Navbar.Toggle >
          <img
            alt=""
            src={fabriwhiteboat}
            height="60"
            className="d-inline-block align-top"
          />
        </Navbar.Toggle>
        <Navbar.Collapse >
          <Nav>
            <Nav.Link href="/schedule">meet us</Nav.Link>
          </Nav>
          <Navbar.Brand href="/">
            <img
                alt=""
                src={fabriwhite}
                height="30"
                className="d-inline-block align-top"
              />
          </Navbar.Brand>
          <Nav>
            <Nav.Link href="/team">team</Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default FabriNavbar;