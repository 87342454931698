import React from 'react';

import '../styles/Home.css';

import Schedule from '../components/ScheduleCalendly.js';

function MeetUs() {
  return (
    <div className='home-container'>
        <Schedule />
    </div>
  );
}

export default MeetUs;
