import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from './pages/Home.js';
import Team from './pages/Team.js';
import Layout from './pages/Layout';
import MeetUs from './pages/MeetUs';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element = {<Layout />} >
          <Route index element={<Home />}/> 
          <Route path='team' element={<Team />}/>
          <Route path='schedule' element={<MeetUs />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
