import React, { Component } from 'react';
import sandbox from '../assets/sandbox.png';
import '../styles/BackedBy.css';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class BackedBy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  }
  render() {
    return (
      <Container>
        <Col className="backed-by">
          <Row className="justify-content-center">
            Backed by
          </Row>
          <Row className="justify-content-center">
            <img 
              className="sandbox-img"
              src={sandbox}
              alt=""
            />
          </Row>
        </Col>
      </Container>
    );
  }
}

export default BackedBy;